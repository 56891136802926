<template>
	<div>

		<div 
		class="inputmask" 
		v-if="isListOpen"
		@click="closeWithoutSelection"
		></div>

		<div class="inputable">


			<label :for="fieldname">{{label}}<span v-if="isRequired" class="req">&nbsp;*</span></label>

			<div class="dropdown" :class="{'open': isListOpen}">

				<input
				type="text"
				autocomplete="off"
				id="fieldname"
				v-model="textInField"
				@input="userChangedInput"
				:class="{hasSuccess: giveSuccessClass}"
				@keyup.prevent.down="down"
				@keyup.prevent.up="up"
				@keyup.prevent.enter="enter"
				@keyup.delete = "backspace"
				:placeholder = "placeholder"
				>

				<!-- Toggler -->
				<div class="toggle" @click="toggleSuggestions()">
					<span class="arrow-up" v-if="isListOpen">▲</span>
					<span class="arrow-down" v-if="!isListOpen">▼</span>
				</div>

				<!-- Suggestions list -->
				<ul 
				class="suggestion-list"
				v-if="isListOpen" 
				style="background: #fff;">
				<li 
				v-for="(option, index) in suggestionsList" 
				@click="selectThisOption(option, index)" 
				:key="index"
				:class="{'active': currentActiveSuggestion === index}"
				>
				{{option.description}}
			</li>
			<li v-if="noResult" @click="resetField">{{$t('inputLabel.noResult')}}</li>
		</ul>
	</div>
</div>

<div class="errorMessage" v-if="isInError">{{errorMessage}}</div>
<div class="helper">{{helper}}</div>

</div>
</template>

<!-- ================================================================================== -->

<script>
	import customLog from "../mixins/CustomLog";
	export default {
		name:"FreeSelect",
		mixins: [customLog],
		props: {
			listOptions : {
				type: Array,
				required: true
			},
			modelValue: {
				required: true,
			},
			caractersBeforeOpenList: {
				type: Number,
				required: false,
				default: 3
			},
			autocomplete: {
				type: Boolean,
				required: false,
				default: true
			},
			label: {
				type: String
			},
			isRequired: {
				type: Boolean
			},
			placeholder: {
				type: String
			},
			helper: {
				type: String
			},
			fieldname: {
				type: String
			},
		},

		data(){
			return {
				isListOpen: false,
				suggestionsList: [],
				giveSuccessClass: false,
				displayInTheField: null,
				currentActiveSuggestion: null,
				isInError: false,
				noResult: false,
			}
		}, 

		computed: {

			textInField: {
				/* It is what is displayed or send by the input */
				get(){					
					return this.displayInTheField;
				},
				set(newVal){
					/* I'm changing the description */
					this.log(`Description is changed for ${newVal}`, 'low'); 
					this.displayInTheField = newVal;			
				}
			}
		},
		watch: {},
		methods: {

			enter(){
				if(this.isListOpen){
					let index = this.currentActiveSuggestion;
					this.suggestionsList[index];
					this.selectThisOption(this.suggestionsList[index], index)
				}
			},

			down(){
				this.makeNewSuggestionList(this.autocomplete);
				if(this.isListOpen && this.currentActiveSuggestion < this.listOptions.length -1){
					this.downOneItem();
				}else{
					this.isListOpen = true;
				}

			},

			up(){
				if(this.isListOpen && this.currentActiveSuggestion > 0){
					this.upOneItem();
				}else{
					this.closeList();
					this.currentActiveSuggestion = null;
					this.giveSuccessClass = false;
				}
			},

			upOneItem(){
				this.currentActiveSuggestion -= 1;
			},

			downOneItem(){
				this.currentActiveSuggestion += 1;
			},

			backspace(){
				this.runFieldValidation();
			},

			closeWithoutSelection(){
				this.log("Closing without selection", 'low');
				this.textInField = "";
				this.isListOpen = false;
				this.giveSuccessClass = false;
				this.$emit('update:modelValue', null);
			},

			selectThisOption(option, index){
				this.log(`User choose item: ${option.id}`, 'low');
				this.displayInTheField = option.description;
				this.runFieldValidation(option.id);
				this.currentActiveSuggestion = index;
				this.closeList();
			},

			toggleSuggestions(){
				this.isListOpen = !this.isListOpen;
				if(this.isListOpen){
					this.displayInTheField = '';
					this.makeNewSuggestionList(this.autocomplete);
					this.giveSuccessClass = false;
				}
			},

			runFieldValidation(value){
				/* TODO Item choosen must be in the list */
				if(this.displayInTheField != ''){
					this.giveSuccessClass = true;
					this.$emit('update:modelValue', value);
					this.$emit('runCheck', {fieldname: this.fieldname, valid: true});
				}
				if(this.displayInTheField === '' || this.displayInTheField === null){
					this.giveSuccessClass = false;
				}
			},

			userChangedInput(){
				/* As user type */
				/* create a new suggestions list */
				if(this.displayInTheField.length === 0){
					this.suggestionsList = [];
					this.closeList();
				}
				if(this.displayInTheField.length >= this.caractersBeforeOpenList){
					this.makeNewSuggestionList(this.autocomplete);
					/* open the suggestion list */
					if(!this.isListOpen){this.openList();}
				}
				if(this.displayInTheField.length >= this.caractersBeforeOpenList && this.suggestionsList.length < 1){
					this.noResult=true;
				}else{
					this.noResult=false;
				}
			},

			makeNewSuggestionList(bool){
				if(bool){
					let temp = this.displayInTheField.toLowerCase();
					/* take what user has typed, check in the official list return result */
					this.suggestionsList = this.listOptions.filter(option => {
						return option.description.toLowerCase().includes(temp);
					});
				}
				if(!bool){
					this.suggestionsList = this.listOptions;
				}
			},

			resetField(){
				this.closeList();
				this.currentActiveSuggestion = null;
				this.displayInTheField = "";
				this.giveSuccessClass = false;
				this.$emit('update:modelValue', null);
			},

			openList(){
				/* remove previous suggestions */
				this.suggestionsList = [];
				/* remove success class */
				this.giveSuccessClass = false;
				/* add new suggestions */
				/* takes what is in the field, compare with known list, return result */
				this.makeNewSuggestionList(this.autocomplete);
				/* DOM: open the list */
				this.isListOpen = true;
			},

			closeList(){
				/* user leave the list, has choosen a suggestion… */
				this.log("CloseList", 'low');
				this.isListOpen = false;				
			},

		},
		created(){},
		mounted(){
			/* S'il y a une valeur dans modelValue, je dois remplir le champ */
			if(this.modelValue !== null && this.modelValue !== ''){
				let selectedOption = this.listOptions.filter(option => {
					return option.id === parseInt(this.modelValue);
				});
				this.displayInTheField = selectedOption[0]?.description;
				this.runFieldValidation();
				/* ! added to "fix" a problem with reparticipations first met on Velux 17272. NOT TESTED YET */
				this.$emit('update:modelValue', parseInt(this.modelValue)); 
				/* TODO : test */
			}
		}
	}
</script>

<!-- ================================================================================== -->


<style scoped>

	.inputable {
		position: relative;
		z-index:20;
	}

	.inputmask {
		position: fixed; 
		top:0; bottom:0; right:0; left:0; 
		z-index:10;
	}

	.dropdown {
		display: inline-block;
		position: relative;
		z-index:60;
	}

	.suggestion-list {
		background-color: rgba(255, 255, 255, 0.95);
		border: 1px solid #ddd;
		list-style: none;
		display: block;
		margin: 0;
		padding: 0.5em;
		width: 100%;
		overflow: hidden;
		overflow-y:auto;
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 21;
		max-height:15em;
	}

	.dropdown .suggestion-list {
		display: none;
	}

	.dropdown.open .suggestion-list {
		display: block;
	}

	.toggle {
		position: absolute;
		top:50%;
		transform:translateY(-60%);
		right:1.25em;
		cursor: pointer;
	}

	.toggle .arrow-up {
		display: none;
	}

	.open .toggle .arrow-up {
		display: inline-block;
	}

	.open .toggle .arrow-down {
		display: none;
	}

	.suggestion-list li {
		cursor: pointer;
		padding:0.25em;
	}

	.suggestion-list li:hover {
		background-color: #fc16;
	}

	li.active {
		background: #fc0;
	}

</style>

