import axios from 'axios'
import store from "../store/store";
//import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";

export default class Axios {
    constructor(config/*, finger*/) {
    /*if (typeof finger === 'undefined') {
        throw new Error('Cannot be called directly')
    }*/

    let baseHeader = {}

    const modifiedConfig = config
    const baseUrl = config.baseUrl

    if (Object.prototype.hasOwnProperty.call(modifiedConfig, 'customHeaders')) {
        baseHeader = {...baseHeader, ...config.customHeaders}
    }

    if (!Object.prototype.hasOwnProperty.call(baseHeader, 'Accept')) {
        baseHeader['Accept'] = 'application/json'
    }

    if (!Object.prototype.hasOwnProperty.call(baseHeader, 'Content-Type')) {
        baseHeader['Content-Type'] = 'application/json'
    }

    let service = axios;

    if (config.baseUrl) {
        service.defaults.baseURL = config.baseUrl
    } else {
        service.defaults.baseURL = baseUrl
    }

    if (config.customHeaders) {
        const finalHeader = {...baseHeader, ...config.customHeaders}
        for (const key of Object.keys(finalHeader)) {
            service.defaults.headers.common[key] = finalHeader[key]
        }
    } else {
        for (const key of Object.keys(baseHeader)) {
            service.defaults.headers.common[key] = baseHeader[key]
        }
    }

    /* Gettings the UTM from the url */
    const findUTM = (name) => {
        let urlParams = new URLSearchParams(window.location.search);
        let utm;
        if(urlParams.has('utm_'+name)){
            utm = urlParams.get('utm_'+name);
            console.log("UTM found: " + utm);
        }
        return utm;
    }


    service.interceptors.request.use(function (config) {
        // Do something before request is sent

        /* Preparing the required data to add to EVERY request (GET, DELETE, POST, PUT, PATCH) */
        const baseBody = {
            Participation: {
                fingerPrint: store.getters.getFingerprint,
            },
            fingerprint: store.getters.getFingerprint,
            lang: store.getters.getLang
        }

        if( config.method === 'get' || config.method === 'delete'  ) {
            config.params = {...config.data, ...baseBody};
        }

        if(     config.method === 'post' || config.method === 'put' || config.method === 'patch' ) {
            if(config.data instanceof FormData) {
                config.data.append('fingerprint', baseBody.fingerprint);
                config.data.append('lang', baseBody.lang);
            }else{
                /* ADD UTMs in the POST request (participation, participationOwner) */
                baseBody.Participation = {
                    fingerPrint: store.getters.getFingerprint,
                    'UTMSource': findUTM('source'),
                    'UTMMedium': findUTM('medium'),
                    'UTMCampaign': findUTM('campaign'),
                };
                baseBody.UTMSource = findUTM('source');
                baseBody.UTMMedium = findUTM('medium');
                baseBody.UTMCampaign = findUTM('campaign');
                config.data = { ...config.data, ...baseBody};
            }
        }

        return config;
    }, function (error) {
            // Do something with request error
        return Promise.reject(error);
    });

    return service;
}
}
