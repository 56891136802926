<template>

	<div id="loader" v-if="pendingParticipationSent">
		<div class="loader-message">
			<div class="loader-visual">&nbsp;</div>
			<div>{{$t('pageTitles.wait')}}</div>
		</div>
	</div>

	<div id="formsContainer">
		<!-- EMPTY CONTAINER REQUIRED BY GOOGLE MAP -->
		<div ref="map"></div>

		<!-- DISPLAY MESSAGES (here and not in App.vue, because it's form.vue that receive the errors) -->
		<toaster	 :class="{'active':isThereMessage}" :msgType="messageType" @closeMe="isThereMessage = false;">
			<template v-slot:text>
				<span style="font-size:0.8em; color: #C00;">{{messageTitle}}</span>
				<ul>
					<li v-for="(message, index) in messageContent" :key="index">&rtrif; {{message}}</li>
				</ul>
			</template>
		</toaster>


		<transition name="fade" mode="out-in">









			<page-form :title="$t('pageTitles.wait')" :grid="false" v-if="currentPage==='loading' || !allowFormToBeDisplayed" key="loading">
				<template v-slot:content>
					<!-- ================================================ -->
					<section>
<!--
					<div style="text-align: center; margin:2em;">Ajouter un petit spinner ici. Ou, comme dans les jeux vidéo, une phase (qui peut être fictive) qui indique la progression ? 
						{{whatAmIDoingNow}}
						</div>
					-->
				</section>
				<!-- ================================================ -->
			</template>
		</page-form>





		<page-form :title="$t('pageTitles.pageError')" :grid="false" v-else-if="currentPage==='pageError'" key="pageError">
			<template v-slot:content>
				<!-- ================================================ -->
				<section>
					<div class="simpletext">
						<p>
							{{$t('pageLitteracy.pageError.intro')}}
							<br>{{$t('pageLitteracy.pageError.solution1')}}
						</p>
						<p v-html="$t('pageLitteracy.pageError.solution2')"></p>
					</div>
				</section>
				<!-- ================================================ -->
			</template>
		</page-form>






		<page-form :title="$t('pageTitles.beforeStart')" v-else-if="currentPage==='beforeStart'" key="beforeStart">
			<template v-slot:content>
				<!-- ================================================ -->
				<section>
					<div class="simpletext">
						<!-- Si la date est dépassée, mais que le status de l'offre n'est toujours pas à "P" qu'afficher -->
						<!-- Uniquement en production -->
						<p v-if="config.isWebsiteInProduction && lead.offerstatus.toUpperCase() != 'P' &&  actionFullDates.registration.start.getTime() <= actionFullDates.current.getTime()">{{$t('pageLitteracy.beforeStart.shouldBeginSoon')}}</p>
							<p v-else>{{$t('pageLitteracy.beforeStart.text')}} <strong>{{getHumanReadableDate(actionFullDates.registration.start)}}</strong>.</p>
						</div>
					</section>
					<!-- ================================================ -->
				</template>
			</page-form>














			<page-form :title="$t('pageTitles.actionEnded')" v-else-if="currentPage==='actionEnded'" key="actionEnded">
				<template v-slot:content>
					<!-- ================================================ -->
					<section>
						<div class="simpletext">
							<p>{{$t('pageLitteracy.actionEnded.text')}}</p>
						</div>
<!--
						<div class="centeredContent">
							<a class="fake_btn" :href="$t('navigation.toCustomerWebsite.link')">{{$t('navigation.toCustomerWebsite.label')}}</a>
						</div>
					-->
				</section>
				<!-- ================================================ -->
			</template>
		</page-form>











		<page-form :title="$t('pageTitles.maxReached')" v-else-if="currentPage==='maxReached'" key="maxReached">
			<template v-slot:content>
				<!-- ================================================ -->
				<section>
					<div class="simpletext">
						<p>{{$t('pageLitteracy.maxReached')}}</p>
					</div>
				</section>
				<!-- ================================================ -->
			</template>
		</page-form>







		<page-form :title="$t('pageTitles.instructions')" v-else-if="currentPage==='instructions'" key="instructions">
			<template v-slot:content>
				<!-- ================================================ -->


				<section>

					{{$t('pageLitteracy.instructions.intro')}}
					<hr>


					<div id="flexSteps">
						<!-- STEP INFO -->
						<div class="step">
							<icons-svg icon="info" class="icon"></icons-svg>
							<div class="instructionsDetails">
								{{$t('pageLitteracy.instructions.steps.info')}}
							</div>
						</div>

						<!-- STEP REGISTRATION -->
						<div class="step">
							<icons-svg icon="registration" class="icon"></icons-svg>
							<div class="instructionsDetails">
								{{$t('pageLitteracy.instructions.steps.register')}}
							</div>
						</div>

						<!-- STEP STORE -->
						<div class="step">
							<icons-svg icon="store" class="icon"></icons-svg>
							<div class="instructionsDetails">
								<ul>
									<li>{{$t('pageLitteracy.instructions.steps.store.line1a')}} <b>{{$t(getHumanReadableDate(actionFullDates.purchaseDates.start))}}</b> {{$t('pageLitteracy.instructions.steps.store.line1b')}} <b>{{$t(getHumanReadableDate(actionFullDates.purchaseDates.end))}}</b></li>
									<li>{{$t('pageLitteracy.instructions.steps.store.line2')}}</li>
									<li>{{$t('pageLitteracy.instructions.steps.store.line3')}}</li>
								</ul>
							</div>
						</div>

						<!-- STEP FORM -->
						<div class="step">					
							<icons-svg icon="form" class="icon"></icons-svg>
							<div class="instructionsDetails">
								<ul>
									<li>{{$t('pageLitteracy.instructions.steps.form.line1')}}</li>
									<li>{{$t('pageLitteracy.instructions.steps.form.line2')}}</li>
									<li>{{$t('pageLitteracy.instructions.steps.form.line3')}}</li>
									<li>{{$t('pageLitteracy.instructions.steps.form.line4')}}</li>
								</ul>
							</div>									
						</div>

						<!-- STEP SURVEY -->
						<div class="step">
							<icons-svg icon="survey" class="icon"></icons-svg>
							<div class="instructionsDetails">
								{{$t('pageLitteracy.instructions.steps.survey')}}
							</div>							
						</div>

						<!-- STEP CASHBACK -->
						<div class="step">
							<icons-svg icon="cashback" class="icon"></icons-svg>
							<div class="instructionsDetails">
								{{$t('pageLitteracy.instructions.steps.cashback')}}
							</div>

						</div>
					</div>

				</section>

				<!-- ================================================ -->
			</template>

			<template v-slot:nav >	
				<section class="navigation" >			
					<button @click.prevent="goto('registration');" v-if="registrationRequired">{{$t('navigation.signup')}}</button >
					<button @click.prevent="goto('form1');" v-else>{{$t('navigation.participate')}}</button>
				</section>
			</template>


		</page-form>






		<page-form :title="$t('pageTitles.registration')" v-else-if="currentPage==='registration'" key="registration">
			<template v-slot:content>
				<!-- ================================================ -->

				<breadcrumbs
				:steps="listOfCrumbsForThisAction"
				:currentStep="0"
				></breadcrumbs>

				<section>
					<div class="content center">
						Avant de vous inscrire, vous voudrez peut-être savoir <a href="#" @click.prevent="goto('instructions');">comment participer</a> ?
					</div>
				</section>

<!--
						<section id="registrationTextAbove">
							<div class="content">
								<p v-if="$i18n.locale ==='fr_BE' || $i18n.locale ==='fr_LU'">

									Les conditions de participation à l’action CLIENT casback peuvent être consultées ici&nbsp;: <a :href="$t('footer.genterms')" @click.prevent="openPDFFile('conditions')" download>{{$t('footer.genterms')}}</a>. La participation implique l'acceptation de ces conditions et de toute mesure que nous devons prendre pour réaliser l’action.
								</p>
								<p v-else>
									De deelnamevoorwaarden aan de CLIENT casback actie vind je hier: <a :href="$t('footer.genterms')" @click.prevent="openPDFFile('conditions')" download>{{$t('footer.genterms')}}</a>. Deelname impliceert de aanvaarding van deze algemene voorwaarden en van elke maatregel die we moeten nemen om de actie uit te voeren.
								</p>

							</div>
						</section>
					-->

					<section>
						<claim-blocs
						:bloc-type="['email', 'whatsapp', 'sms']"
						@goto="goto"
						@mean="setRegistrationMean"
						:lang="$i18n.locale.slice(0,2)"
						:idLead="lead.id"
						:whatsappcodes="currentCodes"
						@errorpresent="displayErrorWith"
						>
						<template v-slot:title>
							<h3>{{$t('sectionTitles.wayOfRegistration')}}</h3>
						</template>
					</claim-blocs>
				</section>

				<section id="registrationTextBelow">
					<div class="content">
						{{$t('pageLitteracy.registration.byRegistering')}} <a v-if="$i18n.locale==='fr_BE' || $i18n.locale==='fr_LU'" href="#">{{$t('pageLitteracy.registration.privacyNotice')}}</a><a v-else href="#">{{$t('pageLitteracy.registration.privacyNotice')}}</a>
					</div>
				</section>

				<!-- ================================================ -->
			</template>
		</page-form>



















		<page-form :title="$t('pageTitles.confirmation')" v-else-if="currentPage==='confirmRegistration'" key="confirmRegistration">
			<template v-slot:content>
				<!-- ================================================ -->

				<breadcrumbs
				:steps="listOfCrumbsForThisAction"
				:currentStep="1"
				></breadcrumbs>

				<section>
					<h3>{{$t('sectionTitles.thanksForRegistration')}}</h3>

					<spacer :h="1"></spacer>

					<div id="confirmSMS" v-if="registrationMean=='phone'" class="centeredContent">
						<p>{{$t('pageLitteracy.confirmRegistration.smsLine1')}}</p>
						<p>{{$t('pageLitteracy.confirmRegistration.smsLine2')}}</p>
					</div>

					<div id="confirmWhatsapp" v-if="registrationMean==='whatsapp'" class="centeredContent">
						<p>{{$t('pageLitteracy.confirmRegistration.whatsappLine1')}}</p>
						<p>{{$t('pageLitteracy.confirmRegistration.whatsappLine2')}}</p>
					</div>

					<div id="confirmMAIL" v-if="registrationMean==='email'" class="centeredContent">
						<p>{{$t('pageLitteracy.confirmRegistration.mailLine1')}}</p>
						<p>{{$t('pageLitteracy.confirmRegistration.mailLine2')}}</p>
					</div>

				</section>

				<!-- ================================================ -->
			</template>

		</page-form>























		<page-form :title="$t('pageTitles.wrongCode')" v-else-if="currentPage==='wrongcode'" key="wrongcode">
			<template v-slot:content>
				<!-- ================================================ -->
				<section>
					<div class="simpletext">
						<p v-html="$t('pageLitteracy.wrongCode.text')"></p>
						<p v-if="registrationRequired">{{$t('pageLitteracy.wrongCode.textregistration')}}</p>
					</div>
				</section>
				<!-- ================================================ -->
			</template>
			<template v-slot:nav v-if="registrationRequired">	
				<section class="navigation">			
					<button @click.prevent="goto('registration');">{{$t('navigation.signup')}}</button>
				</section>
			</template>
		</page-form>





		<page-form :title="$t('pageTitles.wrongCode2')" v-else-if="currentPage==='wrongcode2'" key="wrongcode2">
			<template v-slot:content>
				<!-- ================================================ -->
				<section>
					<div class="simpletext">
						<p v-if="registrationRequired" v-html="$t('pageLitteracy.wrongCode.errorWithReparticipation')"></p>
						<p class="copy" v-html="getUrlParamsHTML()"></p>
					</div>
				</section>
				<!-- ================================================ -->
			</template>
		</page-form>
































		<page-form :title="$t('pageTitles.maintenance')" v-else-if="currentPage==='maintenance'" key="maintenance">
			<template v-slot:content>
				<!-- ================================================ -->
				<section>
					<div style="text-align:center;">
						<p>{{$t('maintenance.line1')}}</p>
						<p>{{$t('maintenance.line2')}}</p>
						<p>{{$t('maintenance.thanks')}}</p>
					</div>
				</section>
				<!-- ================================================ -->
			</template>
		</page-form>























		<page-form :title="$t('pageTitles.form1')" v-else-if="currentPage==='form1' && allowFormToBeDisplayed" key="form1">
			<template v-slot:content>
				<!-- ================================================ -->
				<breadcrumbs
				:steps="listOfCrumbsForThisAction"
				:currentStep="1"
				></breadcrumbs>

				<section class="formgrid">

					<!-- Mandatory field line -->
					<p style="text-align: right; font-size:0.8em;" class="col4"><span style="color: red;">*</span> {{$t('inputLabel.mandatoryFields')}}</p>





					<!-- ============================================ -->
					<h3>{{$t('sectionTitles.aboutYou')}}</h3>
					<!-- ============================================ -->

					<!-- TITLE -->

					<custom-select
					fieldname="idTitle"
					:placeholder="$t('inputsTexts.title.placeholder')"
					:label="$t('inputsTexts.title.label')"
					data-from="titles"
					identifier="id"
					description="description"
					:idLead="lead.id"
					:helper="$t('inputsTexts.title.helper')"
					:isRequired="requiredFieldsShortList.includes('idTitle')"
					:isReparticipation="reparticipation"
					v-model.lazy:modelValue='fieldsValues.idTitle'
					@runCheck="checkIfMustUnlockClaimCashbackButton"
					@maintenanceDetected="activeMaintenancePage"
					></custom-select>

					<spacer :h="0" class="col4"></spacer>

					<!-- FIRSTNAME -->

					<custom-input
					fieldname="firstName"
					:placeholder="$t('inputsTexts.firstname.placeholder')"
					inputType="text"
					:idLead="lead.id"
					:label="$t('inputsTexts.firstname.label')"
					:isRequired="requiredFieldsShortList.includes('firstName')"
					:helper="$t('inputsTexts.firstname.helper')"
					v-model.lazy:modelValue="fieldsValues.firstname"
					@runCheck="checkIfMustUnlockClaimCashbackButton"
					@maintenanceDetected="activeMaintenancePage"
					class=""></custom-input>


					<!-- LASTNAME -->

					<custom-input
					fieldname="lastName"
					:placeholder="$t('inputsTexts.lastname.placeholder')"
					inputType="text"
					:idLead="lead.id"
					:label="$t('inputsTexts.lastname.label')"
					:isRequired="requiredFieldsShortList.includes('lastName')"
					:helper="$t('inputsTexts.lastname.helper')"
					v-model.lazy:modelValue="fieldsValues.lastname"
					@runCheck="checkIfMustUnlockClaimCashbackButton"
					@maintenanceDetected="activeMaintenancePage"
					class=""></custom-input>


					<!-- EMAIL -->

					<custom-input
					fieldname="email"
					:placeholder="$t('inputsTexts.email.placeholder')"
					inputType="text"
					:idLead="lead.id"
					:label="$t('inputsTexts.email.label')"
					:helper="$t('inputsTexts.email.helper')"
					:isRequired="requiredFieldsShortList.includes('email')"
					:disabled="isMailFieldDisabled"
					v-model.lazy:modelValue="fieldsValues.email"
					@runCheck="checkIfMustUnlockClaimCashbackButton"
					@maintenanceDetected="activeMaintenancePage"
					></custom-input>

					<!-- IBAN -->

					<custom-input
					fieldname="IBAN"
					:placeholder="$t('inputsTexts.iban.placeholder')"
					inputType="text"
					:idLead="lead.id"
					:label="$t('inputsTexts.iban.label')"
					:helper="$t('inputsTexts.iban.helper')"
					:isRequired="requiredFieldsShortList.includes('IBAN')"
					v-model.lazy:modelValue="fieldsValues.iban"
					@runCheck="checkIfMustUnlockClaimCashbackButton"
					@maintenanceDetected="activeMaintenancePage"
					></custom-input>

					<spacer :h="1" class="col4"></spacer>


					<!-- Proof of purchase -->
					<upload
					:label="$t('inputsTexts.uploads.ticket.label')"
					fieldname="file"
					post-upload-url-prop="/participations/upload"
					:idLead="lead.id"
					typeOfUpload="ticket"
					:isRequired="true"
					:idParticipationOwner="ownerData.id"
					@runCheck="checkIfMustUnlockClaimCashbackButton"
					@maintenanceDetected="activeMaintenancePage"
					class="col4"
					></upload>

					<!-- Dropzone 1 -->
					<upload
					:label="$t('inputsTexts.uploads.dropzone1.label')"
					fieldname="fileBarCode"
					post-upload-url-prop="/participations/upload"
					:idLead="lead.id"
					typeOfUpload="dropzone1"
					:isRequired="true"
					:idParticipationOwner="ownerData.id"
					@runCheck="checkIfMustUnlockClaimCashbackButton"
					@maintenanceDetected="activeMaintenancePage"
					class="col2"
					></upload>

					<div class="col2 center">
						{{$t('inputsTexts.uploads.helpbarcode')}}<br>
						<br>
						<img src="@/assets/img/codebar.png" alt="" style="display: inline-block;">
					</div>
					

					<spacer :h="1" class="col4"></spacer>

					<!-- Custom Checkbox General Conditions -->
					<custom-checkbox
					fieldname="conditions"
					inputid="demoConditionsCB"
					helper=""
					:isRequired="requiredFieldsShortList.includes('generalCondition')"
					:idLead="lead.id"
					v-model="fieldsValues.cg"
					@runCheck="checkIfMustUnlockClaimCashbackButton"
					special-style="vcheck"
					class="col4"
					>
					<template v-slot:msg>
						{{$t('inputsTexts.conditions.part1')}} <a href="Terms and conditions" @click.prevent="openPDFFile('conditions')" download>{{$t('inputsTexts.conditions.link')}}</a> {{$t('inputsTexts.conditions.part3')}}
					</template>
				</custom-checkbox>


				<!-- <div class="empty col1"></div> -->
				<div class="col4">
					<span v-html="$t('inputsTexts.optin.complement')"></span> 
				</div>

				<!-- Custom Checkbox Optin -->

				<custom-checkbox
				fieldname="optin"
				inputid="optin"
				:helper="$t('inputsTexts.optin.helper')"
				:isRequired="false"
				data-comment-required="This will NEVER be true for a OPT-IN. But for a checkbox…"
				v-model="fieldsValues.optin"
				@runCheck="checkIfMustUnlockClaimCashbackButton"
				special-style="vcheck"
				:idLead="lead.id"
				class="col4">
				<template v-slot:msg>{{$t('inputsTexts.optin.part1')}} <a :href="$t('inputsTexts.optin.link')" target="_blank" rel="noopener">{{$t('inputsTexts.optin.linklabel')}}</a> {{$t('inputsTexts.optin.part2')}}</template>
			</custom-checkbox>


		</section>
		<!-- ================================================ -->
	</template>


	<!-- Nav bar -->
	<template v-slot:nav>
		<section class="navigation">	
			<button @click.prevent="userWantToClaimHisCashback()" :disabled="!isThisButtonActive('btn_cashback_on_form1') || pendingParticipationSent">{{$t('navigation.claimCashback')}}</button> 
		</section>
	</template>

</page-form>















<page-form :title="$t('pageTitles.form2')" v-else-if="currentPage==='form2'" key="form2">
	<template v-slot:content>
		<!-- ================================================ -->
		<breadcrumbs
		:steps="listOfCrumbsForThisAction"
		:currentStep="2"
		></breadcrumbs>

		<section class="formgrid">

			<!-- Mandatory field line -->
			<p style="text-align: right; font-size:0.8em;" class="col4"><span style="color: red;">*</span> {{$t('inputLabel.mandatoryFields')}}</p>


			<!-- ============================================ -->
			<h3>{{$t('sectionTitles.aboutProduct')}}</h3>
			<!-- ============================================ -->

			<!-- WHAT PRODUCT -->
			<popitem
			fieldname="selectProduct"
			:placeholder="$t('inputsTexts.product.placeholder')"
			:label="$t('inputsTexts.product.label')"
			data-from="popitems"
			identifier="id"
			description="description"
			:idLead="lead.id"
			:helper="$t('inputsTexts.product.helper')"
			:isRequired="requiredFieldsShortList.includes('idPOPItem')"
			:isReparticipation="reparticipation"
			v-model.lazy:modelValue='fieldsValues.popitems.popitem1'
			@runCheck="checkIfMustUnlockClaimCashbackButton"
			@maintenanceDetected="activeMaintenancePage"
			class="col2"
			></popitem>

			<!-- DATE OF PURCHASE -->
			<custom-input
			fieldname="purchaseDate"
			inputType="date"
			:idLead="lead.id"
			:placeholder="$t('inputsTexts.datePurchase.placeholder')"
			:label="$t('inputsTexts.datePurchase.label')"
			:helper="$t('inputsTexts.datePurchase.helper')"
			:isRequired="requiredFieldsShortList.includes('purchaseDate')"
			v-model.lazy:modelValue="fieldsValues.purchaseDate"
			@runCheck="checkIfMustUnlockClaimCashbackButton"
			@maintenanceDetected="activeMaintenancePage"
			></custom-input>

			<!-- RETAILER IN A LIST -->
			<custom-select
			fieldname="idPOPRetailer"
			data-from="retailers"
			identifier="id"
			description="name1"
			:placeholder="$t('inputsTexts.retailer.placeholder')"
			:label="$t('inputsTexts.retailer.label')"
			:helper="$t('inputsTexts.retailer.helper')"
			:isRequired="requiredFieldsShortList.includes('idPOPRetailer')"
			:isReparticipation="reparticipation"
			v-model.lazy:modelValue="fieldsValues.retailer"
			@runCheck="checkIfMustUnlockClaimCashbackButton"
			@maintenanceDetected="activeMaintenancePage"
			></custom-select>

			<!-- FREE RETAILER NAME -->
			<!-- ! Comment récupérer ce ou ces IDs ??? -->
			<!-- ! freefield est à "1" pour ces options -->
			<custom-input
			v-if="fieldsValues.retailer"
			fieldname="freeRetailerName"
			inputType="text"
			:idLead="lead.id"
			:placeholder="$t('inputsTexts.freeRetailer.placeholder')"
			:label="$t('inputsTexts.freeRetailer.label')"
			:helper="$t('inputsTexts.freeRetailer.helper')"
			:isRequired="fieldsValues.retailer"
			v-model.lazy:modelValue="fieldsValues.freeRetailer"
			@runCheck="checkIfMustUnlockClaimCashbackButton"
			@maintenanceDetected="activeMaintenancePage"
			></custom-input>


			<!-- Spacer required to force a return to the next line -->
			<spacer :h="0" class="col4"></spacer>

			<!-- FREE SELECT accepts any list with id and description. --> 
			<!-- It returns the ID of the selected item -->
			<!-- This could be upgraded: can I CHOOSE in a prop what it must return. Default should be ID, but I can choose CODE, DESCRIPTION, or even the WHOLE object, maybe. -->
			<!-- This has been useful for a case with validation with only two values (CNK number) -->
			<!-- I must post-process the answer, of course, as LM doesn't wait for it as this -->
			<free-select
			fieldname="NomFictif"
			:listOptions="[
				{id:0, description:'Choix 1'},
				{id:1, description:'Choix 2'},
				{id:2, description:'Choix 3'},
				{id:3, description:'Choix 1, 2 et 3'},
				]"
				v-model:modelValue="fieldsValues.demo"
				:caractersBeforeOpenList="1"
				:autocomplete="true"
				:isRequired="false"
				label="Question custom qui ne vient pas de LM"
				placeholder="Démo d'un champ de type Free-Select"
				@runCheck="checkIfMustUnlockClaimCashbackButton"
				helper="Vous pouvez utiliser les flèches pour vous déplacer."
				class="col2"
				>
			</free-select>


			<!-- SERIAL NUMBER -->
				<!--
				<custom-input
				fieldname="serialNumber"
				inputType="text"
				:idLead="lead.id"
				:placeholder="$t('inputsTexts.serialNumber.placeholder')"
				:label="$t('inputsTexts.serialNumber.label')"
				:helper="$t('inputsTexts.serialNumber.helper')"
				:isRequired="requiredFieldsShortList.includes('serialNumber')"
				v-model.lazy:modelValue="fieldsValues.serialNumber"
				@runCheck="checkIfMustUnlockClaimCashbackButton"
				@maintenanceDetected="activeMaintenancePage"
				></custom-input>
			-->


			<!-- ============================================ -->
			<h3>{{$t('sectionTitles.ticketUpload')}}</h3>
			<!-- ============================================ -->


			<!-- Proof of purchase -->
			<upload
			:label="$t('inputsTexts.uploads.ticket.label')"
			fieldname="file"
			post-upload-url-prop="/participations/upload"
			:idLead="lead.id"
			typeOfUpload="ticket"
			:isRequired="true"
			:idParticipationOwner="ownerData.id"
			@runCheck="checkIfMustUnlockClaimCashbackButton"
			@maintenanceDetected="activeMaintenancePage"
			></upload>

			<!-- More Proof of purchase (dropzone1 up to dropzone6) -->
				<!--
				<upload
				:label="$t('inputsTexts.uploads.dropzone1.label')"
				fieldname="fileBarCode"
				post-upload-url-prop="/participations/upload"
				:idLead="lead.id"
				typeOfUpload="dropzone1"
				:isRequired="true"
				:idParticipationOwner="ownerData.id"
				@runCheck="checkIfMustUnlockClaimCashbackButton"
				@maintenanceDetected="activeMaintenancePage"
				></upload>
			-->







			<div class="col4">{{$t('pageLitteracy.form1.legal')}}</div>

		</section>
		<!-- ================================================ -->
	</template>

	<!-- Nav bar -->
	<template v-slot:nav>
		<section class="navigation">	
			<button @click.prevent="userWantToClaimHisCashback()" :disabled="!isThisButtonActive('btn_cashback_on_form2') || pendingParticipationSent">{{$t('navigation.claimCashback')}}</button> 
		</section>
	</template>

</page-form>

















<page-form :title="$t('pageTitles.choiceSurvey')" v-else-if="currentPage==='choiceSurvey'" key="choiceSurvey">
	<template v-slot:content>
		<!-- ================================================ -->
		<breadcrumbs
		:steps="listOfCrumbsForThisAction"
		:currentStep="3"
		></breadcrumbs>
		<!-- ================================================ -->
		<section>
			<h3>{{$t('pageLitteracy.choiceSurvey.fillSurveyIncite')}}</h3>
			<!-- add text here if needed -->
		</section>
		<!-- ================================================ -->
	</template>
	<template v-slot:nav>
		<!-- ================================================ -->
		<section class="navigation">	
			<button @click.prevent="goto('survey')" :disabled="forceInactiveButtons">{{$t('navigation.doSurvey')}}</button>
			<button @click.prevent="skipSurvey()" :disabled="forceInactiveButtons">{{$t('navigation.skipSurvey')}}</button>
			<button @click.prevent="goto('form1')">{{$t('navigation.previous')}}</button>
		</section>
		<!-- ================================================ -->
	</template>
</page-form>


















<page-form :title="$t('pageTitles.survey')" v-else-if="currentPage==='survey'" key="survey">
	<template v-slot:content>

		<breadcrumbs
		:steps="listOfCrumbsForThisAction"
		:currentStep="3"
		></breadcrumbs>
		<!-- ================================================ -->
		<section class="formgrid">
			
			<h3>{{$t('pageLitteracy.survey.title')}}</h3>
			<!--
			<p>{{$t('pageLitteracy.survey.about')}}</p>
		-->
		<!-- ================== SURVEY ================== -->








		<!-- META : NUMBER -->
		<meta-input
		fieldname="idLeadTMD1"
		placeholder=""
		inputType="number"
		:idLead="lead.id"
		:label="metaIds[0]"
		:data-from="metaIds[0]"
		:isRequired="true"
		helper=""
		v-model.lazy:modelValue="fieldsValues.survey.answersSQ1alt"
		@runCheck="checkIfMustUnlockClaimCashbackButton"
		@maintenanceDetected="activeMaintenancePage"
		class="col2"></meta-input>

		<!-- META : FREE TEXT -->
		<meta-input
		fieldname="idLeadTMD1"
		placeholder=""
		inputType="text"
		:idLead="lead.id"
		:label="metaIds[1]"
		:data-from="metaIds[1]"
		:isRequired="true"
		helper=""
		v-model.lazy:modelValue="fieldsValues.survey.answersSQ2alt"
		@runCheck="checkIfMustUnlockClaimCashbackButton"
		@maintenanceDetected="activeMaintenancePage"
		class="col2"></meta-input>

		<!-- SELECT META -->
		<meta-custom-select
		fieldname="idLeadTMD3"
		:placeholder="$t('inputsTexts.dropdown.placeholder')"
		:data-from="metaIds[2]"
		:idLead="lead.id"
		helper=""
		:isRequired="true"
		:isReparticipation="reparticipation"
		v-model.lazy:modelValue="fieldsValues.survey.answersSQ3"
		@runCheck="checkIfMustUnlockClaimCashbackButton"
		@maintenanceDetected="activeMaintenancePage"
		class="col2"
		>
	</meta-custom-select>

	<!-- SELECT + FREE !? -->
	<!-- TODO ? Unlock a second field -->
	<meta-custom-select
	fieldname="idLeadTMD3"
	:placeholder="$t('inputsTexts.dropdown.placeholder')"
	:data-from="metaIds[3]"
	:idLead="lead.id"
	helper=""
	:isRequired="true"
	:isReparticipation="reparticipation"
	v-model.lazy:modelValue="fieldsValues.survey.answersSQ4"
	@runCheck="checkIfMustUnlockClaimCashbackButton"
	@maintenanceDetected="activeMaintenancePage"
	class="col2"
	>
</meta-custom-select>

<!-- META : DATE -->
<meta-input
fieldname="idLeadTMD1"
placeholder=""
inputType="date"
:idLead="lead.id"
:label="metaIds[4]"
:data-from="metaIds[4]"
:isRequired="true"
helper=""
v-model.lazy:modelValue="fieldsValues.survey.answersSQ1alt"
@runCheck="checkIfMustUnlockClaimCashbackButton"
@maintenanceDetected="activeMaintenancePage"
class="col2"></meta-input>

</section>
<!-- ================================================ -->

</template>

<template v-slot:nav>
	<section class="navigation">	
		<button @click="sendParticipation" :disabled="!isThisButtonActive('btn_cashback_on_survey') || forceInactiveButtons">{{$t('navigation.claimCashback')}}</button>
	</section>
</template>

</page-form>


















<page-form :title="$t('pageTitles.summary')" v-else-if="currentPage==='summary'" key="summary">
	<template v-slot:content>
		<!-- ================================================ -->
		<section>
			<div class="simpletext">
				<p>Cette page affiche un résumé des valeurs des champs communiquées pour confirmation de l'utilisateur</p>
				<p>Utilisée dans le cas "Delhaize", par exemple. Stockée pour futur référence.</p>
				<p>Ajouter optin et conditions sur cette page, si c'est mieux.</p>
			</div>
		</section>
		<!-- ================================================ -->
	</template>
</page-form>




















<page-form :title="$t('pageTitles.alreadySend')" v-else-if="currentPage==='participated'" key="participated">
	<template v-slot:content>
		<!-- ================================================ -->
		<section>
			<div class="simpletext">
				<p>{{$t('pageLitteracy.alreadySend')}}</p>
			</div>
		</section>
		<!-- ================================================ -->
	</template>
</page-form>


























<page-form :title="$t('pageLitteracy.confirmation.title')" v-else-if="currentPage==='confirmation'" key="confirmation">
	<template v-slot:content>

		<breadcrumbs 
		:steps="listOfCrumbsForThisAction" 
		:currentStep="2"
		></breadcrumbs>
		<!-- ================================================ -->
		<section>
			<div class="simpletext">
				<p v-html="$t('pageLitteracy.confirmation.textes.p1')"></p>
				<p>{{$t('pageLitteracy.confirmation.textes.p2')}}</p>


				<!-- IF a coupon is send, show this -->
				<!--
				<p v-if="!skipButtonIsClicked && surveyMustBeFilled" v-html="$t('pageLitteracy.confirmation.textes.coupon')"></p>
			-->

			<p>{{$t('pageLitteracy.confirmation.textes.p3')}}</p>
			<p>
				<em v-html="$t('pageLitteracy.confirmation.textes.p4')"></em>
			</p>
		</div>
	</section>
	<!-- ================================================ -->
</template>
</page-form>

















<page-form title="Demonstration page" subtitle="A page where I can test every thing I want to test" v-else-if="currentPage==='demo'" key="demo">
	<template v-slot:content>

		<breadcrumbs 
		:steps="listOfCrumbsForThisAction" 
		:currentStep="4"
		></breadcrumbs>
		<!-- ================================================ -->

		<section class="formgrid">
			<h3>Titres et textes (grille)</h3>

			<h4>Les colonnes</h4>
			<p class="col4">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab tempore, laboriosam sed soluta. Culpa ipsum deleniti, earum illum quam magnam, ex eos aliquam accusantium dolore harum nam, error corrupti laudantium? Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officia libero debitis vitae, ipsam sunt, provident voluptates consectetur ut corrupti ipsum tempore nam deserunt. Necessitatibus quaerat odio, maiores quis ullam distinctio!</p>
			<p class="col1">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab tempore, laboriosam sed soluta. Culpa ipsum deleniti, earum illum quam magnam, ex eos aliquam accusantium dolore harum nam, error corrupti laudantium?</p>
			<p class="col3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab tempore, laboriosam sed soluta. Culpa ipsum deleniti, earum illum quam magnam, ex eos aliquam accusantium dolore harum nam, error corrupti laudantium? Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus, enim, corporis odio laboriosam doloremque doloribus, assumenda ullam similique possimus deleniti inventore. Ab eligendi, mollitia ea? Vero consequuntur consequatur suscipit corporis!</p>
			<p class="col2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab tempore, laboriosam sed soluta. Culpa ipsum deleniti, earum illum quam magnam, ex eos aliquam accusantium dolore harum nam, error corrupti laudantium?</p>
			<p class="col2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab tempore, laboriosam sed soluta. Culpa ipsum deleniti, earum illum quam magnam, ex eos aliquam accusantium dolore harum nam, error corrupti laudantium?</p>

			<h4 class="col4">Titre h4 can receive a class "col4"</h4>
			<div class="col1"></div>
			<div class="col2">
				<p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ratione sit minima vel nemo consectetur quasi inventore accusantium est delectus repellendus possimus nobis quam facilis assumenda tempore, repellat, dicta quisquam ducimus! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quos amet sunt veniam esse repellendus voluptatibus iure explicabo, nam debitis perferendis, obcaecati aliquam fugiat pariatur placeat voluptates accusamus, ratione hic impedit.</p>

				<h5>Titre h5</h5>
				<p>Lorem ipsum dolor sit amet consectetur adipisicing, elit. Ratione, laboriosam enim sit placeat quia aperiam ullam tempore voluptatem voluptate obcaecati, quaerat neque quam ea molestias sapiente vero, optio autem recusandae. Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet in incidunt quam natus laborum hic, labore doloremque dignissimos officia cumque minima quibusdam quo nam iusto. Expedita temporibus hic labore quidem?</p>
			</div>
		</section>

		<!-- ================================================ -->

		<section class="formgrid">
			<h3>Styles de tags</h3>

			<div class="col2">
				<h4>Balises peu courantes</h4>
				<p><dfn>Lorem ipsum</dfn> dolor sit amet consectetur adipisicing elit. <mark>Ab tempore</mark>, laboriosam sed soluta. Culpa ipsum deleniti, <cite>Earum illum quam</cite> magnam, ex eos aliquam accusantium <q>dolore harum nam</q>, error corrupti laudantium?</p>

				<p>Lorem ipsum dolor sit amet <samp>consectetur adipisicing</samp> elit. Aliquid <abbr title="Global Debt Poor Review">GDPR</abbr> optio ipsa rerum, tempore labore, you can press <kbd>F1</kbd> to continue. Sequi iste sunt explicabo eius, quas nihil culpa quo, cupiditate maiores dolor N<sup>O</sup> 3, la molécule H<sub>2</sub>O. aspernatur recusandae ipsam voluptatem<sup>*</sup>.</p>

				<p>To turn "cheatmode" ON or OFF, Consecutively, press the keys <kbd>c</kbd> + <kbd>h</kbd> + <kbd>e</kbd> + <kbd>a</kbd> + <kbd>t</kbd> + <kbd>n</kbd> + <kbd>a</kbd> + <kbd>v</kbd></p>

				<dl>
					<dt>Term to be defined</dt>
					<dd>This is the definition of the term above. Crazy, isn't it?</dd>
				</dl>

				<details>
					<summary>Cliquez ici pour en savoir plus</summary>
					<h5>Titre</h5>
					<p>Lorem, ipsum, dolor sit amet consectetur adipisicing elit. Ducimus tenetur natus iste quas, possimus nobis, minus eveniet consequuntur, saepe similique neque id vel illum eaque, inventore aliquam atque praesentium doloribus!</p>
					<small>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magnam aliquid, architecto ullam alias repudiandae.</small>
				</details>
			</div>

			<div class="col2">
				<h4>Balises de mise en page "classique"</h4>
				<p>Lorem ipsum dolor, <b>sit amet consectetur</b> adipisicing elit. <u>Ratione sit minima</u> vel nemo <i>consectetur quasi inventore</i> accusantium est <s>delectus repellendus</s> possimus nobis quam facilis assumenda tempore, repellat, dicta quisquam ducimus!</p>

				<h4>Balises sémantiques</h4>
				<p>Lorem ipsum dolor sit amet <strong>consectetur adipisicing</strong>, elit. Ratione, laboriosam enim sit <em>placeat quia aperiam</em> ullam tempore voluptatem voluptate obcaecati, quaerat neque quam ea molestias sapiente vero, optio autem recusandae.</p>
			</div>

			<div class="col4">
				Séparateur ?
				<hr>
				Ouiiiiiii !
			</div>

		</section>

		<!-- ================================================ -->
	</template>
</page-form>












</transition>








<full-test-panel 
v-if="config.isFullTestPanelAllowed && fullTestPanelIsRequested" 
:class="{'retracted':fullTestPanelHidden}"
@clientWantToGoThere="goto"
@swap="toggleTestPanelVisibility"
>
</full-test-panel>








<div id="cheatnav" class="debug" v-if="isDevNavVisible && isAdminNavAllowed" :class="{'retracted':cheatPanelHidden}">
	<div class="swiper" @click="toggleCheatPanel">
		<span v-if="cheatPanelHidden">&lt;</span>
		<span v-if="!cheatPanelHidden">&gt;</span>
	</div>

	<div class="innercheat">

		<h4 style="padding:0.25em 0;">Hdref: {{lead.referencehd}} — Id: {{lead.id}}</h4>

		<fieldset>
			<legend>Infos</legend>
			Acceptance start: <strong>{{getHumanReadableDate(actionFullDates.registration.start)}}</strong> | end: <strong>{{getHumanReadableDate(actionFullDates.participation.end)}}</strong><br> 
			Registration Required: <strong>{{registrationRequired}}</strong><br>
			Survey possible: <strong>{{thereIsASurveyInThisAction}}</strong><br>
			Participations: <strong>{{lead.participations_total}}/{{lead.maxparticipationcounter}} - {{lead.participations_left}} left</strong><br>
			<hr>
			Current page technical name: <strong>{{currentPage}}</strong><br>
			Form display allowed: <strong>{{allowFormToBeDisplayed}}</strong><br>
			
		</fieldset>



		<fieldset>
			<legend>Navigation: "the user's form"</legend>
			<ul>
				<li @click="goto('registration')" :class="{active: currentPage == 'registration'}">Registration</li>
				<li @click="goto('confirmRegistration')" :class="{active: currentPage == 'confirmRegistration'}">Registration confirm.</li>
				<li @click="goto('form1')" :class="{active: currentPage == 'form1'}">Form Page 1</li>
				<li @click="goto('form2')" :class="{active: currentPage == 'form2'}">Form Page 2</li>
				<li @click="goto('choiceSurvey')" :class="{active: currentPage == 'choiceSurvey'}">Choice survey</li>
				<li @click="goto('survey')" :class="{active: currentPage == 'survey'}">Survey</li>
				<li @click="goto('confirmation')" :class="{active: currentPage == 'confirmation'}">Participation confirm.</li>
			</ul>
		</fieldset>

		<fieldset>
			<legend>Fixed pages in all forms</legend>
			<ul>
				<li @click="goto('beforeStart')" :class="{active: currentPage == 'beforeStart'}">Before Action</li>
				<li @click="goto('actionEnded')" :class="{active: currentPage == 'actionEnded'}">After Action</li>
				<li @click="goto('maxReached')" :class="{active: currentPage == 'maxReached'}">Max participation</li>
				<li @click="goto('wrongcode')" :class="{active: currentPage == 'wrongcode'}">Wrong code</li>
				<li @click="goto('wrongcode2')" :class="{active: currentPage == 'wrongcode2'}">Wrong code 2</li>
				<li @click="goto('participated')" :class="{active: currentPage == 'participated'}">Participation already sent</li>
				<li @click="goto('maintenance')" :class="{active: currentPage == 'maintenance'}">Maintenance</li>
				<li @click="goto('loading')" :class="{active: currentPage == 'loading'}">Loading</li>
				<li @click="goto('pageError')" :class="{active: currentPage == 'pageError'}">Error</li>
			</ul>
		</fieldset>

		<fieldset>
			<legend>Optionnal or test pages</legend>
			<ul>
				<li @click="goto('summary')" :class="{active: currentPage == 'summary'}">Summary</li>
				<li @click="goto('instructions')" :class="{active: currentPage == 'instructions'}">Instructions</li>		
				<li @click="goto('demo')" :class="{active: currentPage == 'demo'}">Demo page</li>
			</ul>
		</fieldset>

		<fieldset>
			<legend>Logs</legend>
			<ul>
				<button @click="displayRequiredFieldsList()">Mandatory fields</button>
				<button @click="displayMetaFieldsList()">Meta fields</button>
				<button @click="showDataToBeSent()">Particip. data</button>
				<button @click="testAllLogs()">Custom logs</button>
				<button @click="displayFieldsValues()">FieldsValues</button>
				<button @click="displayFieldsValidity()">Fields Validity</button>
			</ul>
		</fieldset>

		<fieldset>
			<legend>Others</legend>
			<ul>
				<button @click="unlockButtons()">Unlock buttons</button>
				<button @click="loadFor5Sec()">Load 3 sec</button>
				<button @click="fullTestPanelIsRequested = !fullTestPanelIsRequested; fullTestPanelHidden=true;">"Full Test" panel</button>
				<div>
					<button @click="displayMessage('Error on demand', ['You wanted it, you have it!', 'Do\'h!'], 'alert')" style="width:49%; margin-right:1%;">Error</button>
					<button @click="displayMessage('Info on demand', ['This information is free.'], 'info')" style="width:49%; margin-left:1%;">Info</button>
				</div>
				<div>
					<button @click="setLang(1)" style="width:49%; margin-right:1%;">=> FR</button>
					<button @click="setLang(2)" style="width:49%; margin-left:1%;">=> NL</button>
				</div>
				<button @click="allowFormToBeDisplayed = !allowFormToBeDisplayed">Toggle allow form</button>
			</ul>
		</fieldset>
	</div>

</div>
</div>
</template>





















































<!-- ================================================================================== -->

<script>
	/* ======== OTHERS ======== */
	import 'intl-tel-input/build/css/intlTelInput.css';
	import config from "../config";

	/* ======== COMPONENTS ======== */
	import PageForm from '@/components/PageForm';
	import ClaimBlocs from '@/components/ClaimBlocs';
	import CustomInput from "@/components/CustomInput";
	import CustomCheckbox from '@/components/CustomCheckbox';
	import CustomSelect from '@/components/CustomSelect';
	// import CustomRadio from '@/components/CustomRadio';
	import MetaInput from "@/components/MetaInput";
	// import FreeInput from "@/components/FreeInput";
	import FreeSelect from "@/components/FreeSelect";
	import MetaCustomSelect from '@/components/MetaCustomSelect';
	import Popitem from '@/components/Popitem';
	import Spacer from '@/components/Spacer';
	import Breadcrumbs from '@/components/Breadcrumbs';
	import Upload from "@/components/Upload";
	// import PhoneInput from '@/components/PhoneInput';
	// import SurveyCustomSelect from '@/components/SurveyCustomSelect';
	import Toaster from '@/components/Toaster';
	import FullTestPanel from '@/components/FullTestPanel';
	import IconsSvg from '@/components/IconsSvg';
	// import GoogleSelect from "@/components/GoogleSelect";

	/* ======== MIXINS ======== */
	// import GoogleAPI from "../mixins/GoogleAPI";
	import pixel from "../mixins/pixel";
	import Functions from "../mixins/Functions";
	import APICalls from "../mixins/APICalls";
	import Cheat from "../mixins/Cheat";
	import FL from "../mixins/floodlight";
	import customLog from "../mixins/CustomLog";
	import ActionDates from "../mixins/ActionDates";
	import openPDF from "../mixins/openpdf";
	import fieldsvalidator from "../mixins/fieldsvalidity";
	import metaFields from "../mixins/metaFields";
	import ToasterFunctions from "../mixins/ToasterFunctions";
	import NonAutomatedData from "../mixins/NonAutomatedData";







	export default {
		name:"Form",
		

		mixins: [
			ActionDates, 
			APICalls, 
			Cheat,
			customLog,
			fieldsvalidator,
			FL, 
			Functions, 
			// GoogleAPI,
			metaFields,
			NonAutomatedData,
			openPDF,
			pixel, 
			ToasterFunctions,
			],




		components: {
			ClaimBlocs,
			Breadcrumbs,
			CustomInput,
			CustomSelect,
			CustomCheckbox,
			FullTestPanel,
			// GoogleSelect,
			IconsSvg,
			MetaCustomSelect,
			// FreeInput,
			FreeSelect,
			MetaInput, // is it obsolete? replaced by customInput?
			Spacer,
			Upload,
			PageForm,
			// PhoneInput,
			Toaster,
			Popitem,
		},








		data () {
			return {

				isAdminNavAllowed : config.isAdminNavAllowed, 
				isMaintenanceCheckRequired : config.allowMaintenanceCheck,
				currentCodes: null,
				ownerData: {},
				popitemlist : [],
				reparticipation: false,
				metaIds: [],
				dateMessage: null,
				smsHelper: '', // used as modelValue. Maybe change name. …later.
				pendingParticipationSent: false,
				composedDataForParticipation: {},
				fullTestPanelHidden: true,
				fullTestPanelIsRequested: false,
				registrationRequired: true,
				isUploadPresent: false,


				fieldsValues : {
					bic:null,
					iban:null,
					idTitle: null,
					idGender: null,
					uniqueCode : null,
					firstname: null,
					lastname: null,
					email: null,
					popitem: null, // * old one
					popitems:{
						popitem1 : null,
						popitem2 : null,
						popitem3 : null,
					},
					demo: null,
					retailer: null,
					freeRetailer:null,
					purchaseDate: null,
					serialNumber: null,
					language: null,
					birthdate : null,
					phone1: null,
					phone2: null,
					/* Adresse 1 */
					street1 : null,
					street2 : null,
					housenumber : null,
					boxnumber : null,
					postalcode : null,
					city : null,
					idcountrycode : null,
					/* Adresse 2 (1th time in 17017) */
					/* … */

					societe1 : null,
					societe2 : null,
					vatCountry: null,
					vatCode : null,
					confidentiality :null,
					cg : null,
					optin: 0, // default value needed
					/* TODO : automatiser ici, car je les ajoute encore manuellement */
					/* beforemount : read list of meta => create as many "answersSQn" as required and place them here */
					survey:{
						answersSQ1: null,
						answersSQ1alt: null,
						answersSQ2: null,
						answersSQ2alt: null,
						answersSQ3: null,
						answersSQ3alt: null,
					}
				}
			};
		},





		methods: {	
			activeMaintenancePage(){
				this.log("Request for maintenance page", "alert");
				this.goto('maintenance');
			},

			treatProofOfPurchaseDetailConsumer(category) {
				this.log("treatProofOfPurchaseDetailConsumer", 'group');

				this.bigOwnerData[category].forEach((pop, index) => {
					this.log(pop);
					this.fieldsValues.popitems["popitem" + (index+1)] = parseInt(this.bigOwnerData[category][index].idpopitem);
				});

				this.log('', 'groupEnd');
			},

			putDataIntoFieldsForAReparticipation(){
				let categories = Object.keys(this.bigOwnerData);
				this.log(categories);
				categories.forEach((category) => {

					this.log("Reading " + category);

					if(category === 'ParticipationMetaData'){
						this.log("Treating " + category);
						this.treatParticipationMetaData(category);
					}

					if(category === "ProofOfPurchaseHeaderConsumer"){
						this.log("Treating " + category);
						this.treatProofOfPurchaseHeaderConsumer(category);
					}

					if(category === "ProofOfPurchaseDetailConsumer" && this.bigOwnerData["ProofOfPurchaseDetailConsumer"]) {
						this.log("Treating " + category);
						this.treatProofOfPurchaseDetailConsumer(category);
					}

					if(category === 'ParticipationOwner'){
						this.log("Treating " + category);
						this.treatParticipationOwnerData(category);
					}

					if (category === "Participation"){
						this.log("Nothing to do (yet) inside " + category);
					}

				});
			},

			/* RETURNS THE ID BASED ON CURRENT LOCALE */
			getIdOfLanguage(){
				let defaultIdLang = 2;
				let idLang = defaultIdLang;
				let currentLocale = this.$i18n.locale.slice(0,2).toUpperCase();
				/* ! this must be replaced by a comparaison with data received from API => See APIgetLocalizingData() */
				/* TODO replace stuff here */
				if (currentLocale === 'FR'){
					idLang = 1;
				}else{
					idLang = defaultIdLang;
				}
				/* END */
				return idLang;
			},






			composeProofOfPurchaseDetailConsumer(popitemListLength){
				/* INFO ProofOfPurchaseDetailConsumer must be present if there is an info about product (popitem, serialNumber) otherwise, it must be left empty*/
				
				/* ==== POPITEM ==== */
				if (popitemListLength === 0){
					this.log("There is no POPItem, so I don't add ProofOfPurchaseDetailConsumer to data", 'info');
					/* null */
				}
				if (popitemListLength === 1){
					this.log("There is only ONE POPItem, so I fill ProofOfPurchaseDetailConsumer whith this unique data", 'info');
					this.composedDataForParticipation.ProofOfPurchaseDetailConsumer = {"idPOPItem": this.popitemlist[0].id, "quantity": 1}; 
					/* is an object */
				}
				if (popitemListLength > 1){
					this.log("THERE IS Multiple POPItems, so I must check what user CHOOSE in the form", 'info');
					this.composedDataForParticipation.ProofOfPurchaseDetailConsumer = this.returnsOnlyNonEmptyPopitems(); 
					/* return an array */
				}

				/* ==== SERIAL NUMBER ==== */
				/* If there is a serial number, we must add it */
				if(this.fieldsValues.serialNumber){
					/* If there is no popitem, we must create it first before adding a property serialNumber */
					if(this.composedDataForParticipation.ProofOfPurchaseDetailConsumer){
						this.composedDataForParticipation.ProofOfPurchaseDetailConsumer = {};
					}
					/* Now that it exists, we can add the property */
					this.composedDataForParticipation.ProofOfPurchaseDetailConsumer.serialNumber = this.fieldsValues.serialNumber;
				}
			},

			composeParticipationOwner(){
				let ParticipationOwner = {
					"idParticipationOwner": this.ownerData.id,
					"idTitle": this.fieldsValues.idTitle,
					"idGender":  this.fieldsValues.idGender,
					"phoneNumber1": this.fieldsValues.phone1,
					"lastName": this.fieldsValues.lastname,
					"firstName": this.fieldsValues.firstname,
					"email": this.fieldsValues.email,
					"IBAN": this.fieldsValues.iban,
					"VATNumber": this.fieldsValues.VATNumber,
					"optin": this.fieldsValues.optin,
					"generalCondition": this.fieldsValues.cg,
					"idLanguageCode": this.getIdOfLanguage(),
					"idCountryCode": this.fieldsValues.idcountrycode,
					"companyName1": this.fieldsValues.companyName1, 
					"street1": this.removeSpecialChars(this.fieldsValues.street1),
					"street2": this.removeSpecialChars(this.fieldsValues.street2),
					"houseNumber": this.fieldsValues.housenumber,
					"boxNumber": this.fieldsValues.boxnumber,
					"city": this.removeSpecialChars(this.fieldsValues.city),
					"postalCode": this.fieldsValues.postalcode,
					"keyAddress": this.fieldsValues.placeId,
				}
				return this.cleanNullValues(ParticipationOwner);
			},

			removeSpecialChars(str){
				if(str){
					this.log(`Removing special char from "${str}"`, "low");
					str = str.replaceAll('œ', 'oe');
					str = str.replaceAll('Œ', 'OE');
					this.log(`=> "${str}"`, "low");
				}
				return str;
			},

			/* TODO décomposer ceci pour envoyer séparer les propriétés optionnelles dans certaines actions */
			returnFieldsValue(){
				let theFieldsValues = {
					"Authorization" : this.oauth.token_type + ' ' + this.oauth.access_token,
					"idLeadManagement": this.lead.id,
					"isUploadPresent": this.isUploadPresent,
					"userSystemInfo": null, // TODO update this later, when back-end is ready

					"Participation": {
						"idParticipationType": 1,
						'fingerprint': this.$store.getters.getFingerprint,
					},
					
					"ParticipationOwner": this.composeParticipationOwner(),
					"ParticipationMetaData": this.composeParticipationMetaData(),
				};

				/* Must only add this if it contains at least one value */
				let ProofOfPurchaseHeaderConsumer = this.composeProofOfPurchaseHeaderConsumer();
				let size = Object.keys(ProofOfPurchaseHeaderConsumer).length;
				if (size > 0){
					theFieldsValues['ProofOfPurchaseHeaderConsumer'] = ProofOfPurchaseHeaderConsumer;
				}

				/* Last console check */
				this.log("The gathered field values are as such:", 'info');
				this.log(theFieldsValues);
				return theFieldsValues;
			},

			composeParticipationMetaData(){
				/* If a coupon is needed, a special part in the mail is required and depend on this return */
				/* If no coupon is needed, I return nothing */
				if (this.thereIsASurveyInThisAction){
					return [	{
						"idLeadTMD": 1,
						"idLeadTMDV": this.surveyMustBeFilled ? 1 : 2
					}];
				}
			},

			composeProofOfPurchaseHeaderConsumer(){
				let POPHC = {
					"idPOPRetailer": this.fieldsValues.retailer,
					"freeRetailerName" : this.fieldsValues.freeRetailer,
					"purchaseDate" : this.fieldsValues.purchaseDate,
				};
				return this.cleanNullValues(POPHC);
			},

			/* TODO : decompose this long function into smaller ones */
			composeData(){
				this.log("prep of the DATA before sending participation", 'title');

				/* Whitening */
				this.composedDataForParticipation = {};

				/* getting fields values */
				this.composedDataForParticipation = this.returnFieldsValue();

				/* getting popitem(s) */
				let itemListLength = this.popitemlist.length;

				this.log('List of products','group')
				this.log({...this.popitemlist});
				this.log(`There are ${itemListLength} elements in the popitems list`);
				this.log('', 'groupEnd');

				this.composeProofOfPurchaseDetailConsumer(itemListLength);

				/* getting Metas */







				/*
				this.checkSurvey(this.metaIds[0], answersSQ1');

				checkSurvey(questId, survId){
					if(this.fieldsValues.survey[survId] !== '' && this.fieldsValues.survey[survId] !== null){
						this.composedDataForParticipation.ParticipationMetaData.push({
							"idLeadTMD" : config.enquete[questId],
							"idLeadTMDV" : parseInt(this.fieldsValues.survey[survId]])
						});
					}
				}
				*/


				/* TODO XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX */

				if(this.fieldsValues.survey.answersSQ1 !== '' && this.fieldsValues.survey.answersSQ1 !== null) {
					/* dropdown */
					this.composedDataForParticipation.ParticipationMetaData.push({
						"idLeadTMD" : this.metaIds[0],
						"idLeadTMDV" : parseInt(this.fieldsValues.survey.answersSQ1)
					});
				}
				if(this.fieldsValues.survey.answersSQ1alt !== '' && this.fieldsValues.survey.answersSQ1alt !== null) {
					/* Input free text */
					this.composedDataForParticipation.ParticipationMetaData.push({
						"idLeadTMD" : config.enquete.question1,
						"idLeadTMDV" : '',
						"answer": this.fieldsValues.survey.answersSQ1alt
					});
				}

				if(this.fieldsValues.survey.answersSQ2 !== '' && this.fieldsValues.survey.answersSQ2 !== null) {
					/* dropdown */
					this.composedDataForParticipation.ParticipationMetaData.push({
						"idLeadTMD" : config.enquete.question2,
						"idLeadTMDV" : parseInt(this.fieldsValues.survey.answersSQ2)
					});
				}
				if(this.fieldsValues.survey.answersSQ2alt !== '' && this.fieldsValues.survey.answersSQ2alt !== null) {
					/* Input free text */
					this.composedDataForParticipation.ParticipationMetaData.push({
						"idLeadTMD" : config.enquete.question2,
						"idLeadTMDV" : '',
						"answer": this.fieldsValues.survey.answersSQ2alt
					});
				}

				if(this.fieldsValues.survey.answersSQ3 !== '' && this.fieldsValues.survey.answersSQ3 !== null) {
					/* dropdown */
					this.composedDataForParticipation.ParticipationMetaData.push({
						"idLeadTMD" : config.enquete.question3,
						"idLeadTMDV" : parseInt(this.fieldsValues.survey.answersSQ3)
					});
				}
				if(this.fieldsValues.survey.answersSQ3alt !== '' && this.fieldsValues.survey.answersSQ3alt !== null) {
					/* Input free text */
					this.composedDataForParticipation.ParticipationMetaData.push({
						"idLeadTMD" : config.enquete.question3,
						"idLeadTMDV" : '',
						"answer": this.fieldsValues.survey.answersSQ3alt
					});
				}

				this.log(this.composedDataForParticipation);
				this.log("End of preview of data to be send", 'low');
			},











			areConditionsToSendTheParticipationMet(){
				this.log("Check sending conditions", 'low');
				let conditionsAreMet = true;
				/* TODO Re-write conditions here */
				this.log(`Sending conditions: ${conditionsAreMet}`, 'wip');
				return conditionsAreMet;
			},


			whatIfIGetTheCountries(data){
				/* TODO SOMETHING? */
				this.log(data); /* Only for avoiding lint message */
				this.log("I Got the country list. Do I need to do something with it now?", 'todo');
			},


			whatIfICantGetTheCountries(error){
				/* TODO DISPLAY AN ERROR? */
				this.log("I can't get the country list. What can I do now?",'todo');
				console.log(error);
			},

			getUserEnvironment(){
				/* GET some data about the device of the user to be sent for possible debug help */
				let userEnvDetails = [];
				userEnvDetails.push(window.screen);
				userEnvDetails.push(window.navigator);
				/* does he touched the url? */
				userEnvDetails.push(window.location);
				this.log("ENVIRONMENT INFORMATIONS", "sep");
				this.log(userEnvDetails);
			},

		}, /* End of methods */

			beforeMount(){
				console.clear();	
				this.consoleDateOfBuild();
				if(config.allowMaintenanceCheck){
					this.checkMaintenance();
				}else{
					this.log("No maintenance check required, proceeding…");
					this.startAction();
					this.getUserEnvironment();
				}
			},

			mounted(){
				this.checkUrlForTestPanel();
			},

			watch: {
				'$store.getters.getLocale': function(newLocale, oldLocale){
					this.log(`${oldLocale} --> ${newLocale}`, 'low');
					if(this.registrationRequired){
						this.updateWhatsappCodes();
					}
				},
			},
		}
	</script>

	<!-- ================================================================================== -->

	<style lang="scss" scoped>
		.fade-enter-active,
		.fade-leave-active {
			transition: opacity .2s, height .2s ease-in-out;
		}
		.fade-enter-from,
		.fade-leave-to {
			opacity: 0;
			height:0;
		}
	</style>
